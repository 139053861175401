import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  JSON: { input: any; output: any; }
  JSONObject: { input: any; output: any; }
};

export type ApiRequest = {
  __typename?: 'ApiRequest';
  _id: Scalars['ID']['output'];
  body: Scalars['JSONObject']['output'];
  createdAt: Scalars['Date']['output'];
  finishedAt: Scalars['Date']['output'];
  origin: ApiRequestOrigin;
  requestedAt: Scalars['Date']['output'];
  statusCode: Scalars['Int']['output'];
  type: ApiRequestType;
};

export enum ApiRequestOrigin {
  Dashboard = 'Dashboard',
  External = 'External'
}

export type ApiRequestResponse = {
  __typename?: 'ApiRequestResponse';
  apiRequests: Array<ApiRequest>;
  hasMore: Scalars['Boolean']['output'];
};

export enum ApiRequestType {
  CancelQueuedCommunication = 'CancelQueuedCommunication',
  FlushModuleQueuedCommunications = 'FlushModuleQueuedCommunications',
  GetModulesDetails = 'GetModulesDetails',
  GetPowerBanksDetails = 'GetPowerBanksDetails',
  LendPowerBank = 'LendPowerBank',
  RestartModule = 'RestartModule',
  UnlockModulePowerBanks = 'UnlockModulePowerBanks',
  UpdateModuleIntervals = 'UpdateModuleIntervals',
  UpdateModuleMiddlewareUrl = 'UpdateModuleMiddlewareUrl',
  UpdateModuleSerialNumber = 'UpdateModuleSerialNumber'
}

export type ApiRequestsFilters = {
  afterTimestamp?: InputMaybe<Scalars['Date']['input']>;
  beforeTimestamp?: InputMaybe<Scalars['Date']['input']>;
  origin?: InputMaybe<ApiRequestOrigin>;
  serialNumber: Scalars['String']['input'];
  type?: InputMaybe<ApiRequestType>;
};

export enum ConnectivityState {
  Connected = 'Connected',
  Disconnected = 'Disconnected'
}

export type LoginResponse = {
  __typename?: 'LoginResponse';
  error?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
};

export type Message = {
  __typename?: 'Message';
  _id: Scalars['ID']['output'];
  body: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  sender: MessageSender;
  topic: Scalars['String']['output'];
};

export enum MessageCommand {
  AcknowledgePowerBankLoanConfirmation = 'AcknowledgePowerBankLoanConfirmation',
  AllSlotsInformation = 'AllSlotsInformation',
  ConfigureModule = 'ConfigureModule',
  ConfigureModuleConfirmation = 'ConfigureModuleConfirmation',
  ConfirmModuleInformation = 'ConfirmModuleInformation',
  ConfirmPowerBankReturn = 'ConfirmPowerBankReturn',
  EjectPowerBank = 'EjectPowerBank',
  LendPowerBank = 'LendPowerBank',
  ModuleInformation = 'ModuleInformation',
  PowerBankEjectionConfirmation = 'PowerBankEjectionConfirmation',
  PowerBankLoanConfirmation = 'PowerBankLoanConfirmation',
  PowerBankReturn = 'PowerBankReturn',
  SlotInformation = 'SlotInformation'
}

export enum MessageSender {
  Module = 'Module',
  Server = 'Server'
}

export type MessagesFilters = {
  afterTimestamp?: InputMaybe<Scalars['Date']['input']>;
  beforeTimestamp?: InputMaybe<Scalars['Date']['input']>;
  commands?: InputMaybe<Array<MessageCommand>>;
  moduleSerialNumber: Scalars['String']['input'];
  sender?: InputMaybe<MessageSender>;
};

export type MessagesResponse = {
  __typename?: 'MessagesResponse';
  hasMore: Scalars['Boolean']['output'];
  messages: Array<Message>;
};

export type Module = {
  __typename?: 'Module';
  CI: Scalars['String']['output'];
  ICCID: Scalars['String']['output'];
  IMEI: Scalars['String']['output'];
  LAC: Scalars['String']['output'];
  _id: Scalars['ID']['output'];
  firmwareVersion: Scalars['String']['output'];
  isOnline: Scalars['Boolean']['output'];
  lastSeenAt: Scalars['Date']['output'];
  serialNumber: Scalars['String']['output'];
  signalIntensity: Scalars['Int']['output'];
  slots: Array<Slot>;
};

export type ModuleActionResponse = {
  __typename?: 'ModuleActionResponse';
  error?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type ModuleAuthorization = {
  __typename?: 'ModuleAuthorization';
  _id: Scalars['ID']['output'];
  createdAt: Scalars['Date']['output'];
  moduleSerialNumber: Scalars['String']['output'];
};

export type ModuleAuthorizationResponse = {
  __typename?: 'ModuleAuthorizationResponse';
  error?: Maybe<Scalars['String']['output']>;
  moduleAuthorizations?: Maybe<Array<ModuleAuthorization>>;
};

export type ModuleAuthorizationsFilters = {
  moduleSerialNumber: Scalars['String']['input'];
};

export type ModuleAuthorizationsResponse = {
  __typename?: 'ModuleAuthorizationsResponse';
  hasMore: Scalars['Boolean']['output'];
  moduleAuthorizations: Array<ModuleAuthorization>;
};

export type ModuleConnectivityState = {
  __typename?: 'ModuleConnectivityState';
  _id: Scalars['ID']['output'];
  createdAt: Scalars['Date']['output'];
  moduleSerialNumber: Scalars['String']['output'];
  state: ConnectivityState;
};

export type ModuleConnectivityStatesFilters = {
  moduleSerialNumber: Scalars['String']['input'];
};

export type ModuleConnectivityStatesResponse = {
  __typename?: 'ModuleConnectivityStatesResponse';
  hasMore: Scalars['Boolean']['output'];
  moduleConnectivityStates: Array<ModuleConnectivityState>;
};

export type ModulesFilters = {
  isOnline?: InputMaybe<Scalars['Boolean']['input']>;
  moduleSerialNumber: Scalars['String']['input'];
  powerBankSerialNumber: Scalars['String']['input'];
};

export type ModulesInfo = {
  __typename?: 'ModulesInfo';
  offlineCount: Scalars['Int']['output'];
  potentiallyOfflineCount: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type ModulesResponse = {
  __typename?: 'ModulesResponse';
  hasMore: Scalars['Boolean']['output'];
  modules: Array<Module>;
};

export type Mutation = {
  __typename?: 'Mutation';
  cancelQueuedCommunication: QueuedCommunicationsActionResponse;
  createModuleAuthorization: ModuleAuthorizationResponse;
  ejectModulePowerBanks: ModuleActionResponse;
  ejectSinglePowerBank: ModuleActionResponse;
  flushModuleQueuedCommunications: QueuedCommunicationsActionResponse;
  login: LoginResponse;
  restartModule: ModuleActionResponse;
  updateModuleIntervals: ModuleActionResponse;
  updateModuleMiddlewareUrl: ModuleActionResponse;
  updateModuleSerialNumber: ModuleActionResponse;
};


export type MutationCancelQueuedCommunicationArgs = {
  queuedCommunicationId: Scalars['String']['input'];
};


export type MutationCreateModuleAuthorizationArgs = {
  moduleSerialNumbers?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type MutationEjectModulePowerBanksArgs = {
  serialNumber: Scalars['String']['input'];
};


export type MutationEjectSinglePowerBankArgs = {
  moduleSerialNumber: Scalars['String']['input'];
  slotIndex: Scalars['Int']['input'];
};


export type MutationFlushModuleQueuedCommunicationsArgs = {
  moduleSerialNumber: Scalars['String']['input'];
};


export type MutationLoginArgs = {
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationRestartModuleArgs = {
  serialNumber: Scalars['String']['input'];
};


export type MutationUpdateModuleIntervalsArgs = {
  heartbeatInterval: Scalars['Int']['input'];
  reportingInterval: Scalars['Int']['input'];
  serialNumber: Scalars['String']['input'];
};


export type MutationUpdateModuleMiddlewareUrlArgs = {
  serialNumber: Scalars['String']['input'];
  url: Scalars['String']['input'];
};


export type MutationUpdateModuleSerialNumberArgs = {
  newSerialNumber: Scalars['String']['input'];
  serialNumber: Scalars['String']['input'];
};

export type OngoingCommunication = {
  __typename?: 'OngoingCommunication';
  _id: Scalars['String']['output'];
  command: ServerMessageCommand;
  createdAt: Scalars['Date']['output'];
  data: Array<Scalars['String']['output']>;
  messageId: Scalars['Int']['output'];
  moduleSerialNumber: Scalars['String']['output'];
  orderedAt: Scalars['Date']['output'];
  shouldAddMessageId: Scalars['Boolean']['output'];
  shouldHaveAnswer: Scalars['Boolean']['output'];
};

export type OngoingCommunicationsFilters = {
  moduleSerialNumber: Scalars['String']['input'];
};

export type OngoingCommunicationsResponse = {
  __typename?: 'OngoingCommunicationsResponse';
  hasMore: Scalars['Boolean']['output'];
  ongoingCommunications: Array<OngoingCommunication>;
};

export type PowerBank = {
  __typename?: 'PowerBank';
  _id: Scalars['ID']['output'];
  batteryLevel: Scalars['Int']['output'];
  moduleSerialNumber?: Maybe<Scalars['String']['output']>;
  serialNumber: Scalars['String']['output'];
  slotIndex?: Maybe<Scalars['Int']['output']>;
  transactionId?: Maybe<Scalars['String']['output']>;
};

export type PowerBanksFilters = {
  isInTransaction?: InputMaybe<Scalars['Boolean']['input']>;
  powerBankSerialNumber: Scalars['String']['input'];
  transactionId: Scalars['String']['input'];
};

export type PowerBanksInfo = {
  __typename?: 'PowerBanksInfo';
  loanedCount: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PowerBanksResponse = {
  __typename?: 'PowerBanksResponse';
  hasMore: Scalars['Boolean']['output'];
  powerBanks: Array<PowerBank>;
};

export type Query = {
  __typename?: 'Query';
  apiRequests: ApiRequestResponse;
  apiRequestsForChart: Array<ApiRequest>;
  messages: MessagesResponse;
  moduleAuthorizations: ModuleAuthorizationsResponse;
  moduleConnectivityStates: ModuleConnectivityStatesResponse;
  modules: ModulesResponse;
  modulesInfo: ModulesInfo;
  ongoingCommunications: OngoingCommunicationsResponse;
  powerBanks: PowerBanksResponse;
  powerBanksInfo: PowerBanksInfo;
  queuedCommunications: QueuedCommunicationsResponse;
  webhookRequests: WebhookRequestResponse;
};


export type QueryApiRequestsArgs = {
  filters: ApiRequestsFilters;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};


export type QueryApiRequestsForChartArgs = {
  timePeriodInHours: Scalars['Int']['input'];
};


export type QueryMessagesArgs = {
  filters: MessagesFilters;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};


export type QueryModuleAuthorizationsArgs = {
  filters: ModuleAuthorizationsFilters;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};


export type QueryModuleConnectivityStatesArgs = {
  filters: ModuleConnectivityStatesFilters;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};


export type QueryModulesArgs = {
  filters: ModulesFilters;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};


export type QueryOngoingCommunicationsArgs = {
  filters: OngoingCommunicationsFilters;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};


export type QueryPowerBanksArgs = {
  filters: PowerBanksFilters;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};


export type QueryQueuedCommunicationsArgs = {
  filters: QueuedCommunicationsFilters;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};


export type QueryWebhookRequestsArgs = {
  filters: WebhookRequestsFilters;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type QueuedCommunication = {
  __typename?: 'QueuedCommunication';
  _id: Scalars['String']['output'];
  command: ServerMessageCommand;
  createdAt: Scalars['Date']['output'];
  data: Array<Scalars['String']['output']>;
  moduleSerialNumber: Scalars['String']['output'];
  shouldAddMessageId: Scalars['Boolean']['output'];
  shouldHaveAnswer: Scalars['Boolean']['output'];
};

export type QueuedCommunicationsActionResponse = {
  __typename?: 'QueuedCommunicationsActionResponse';
  error?: Maybe<Scalars['String']['output']>;
  queuedCommunications: Array<QueuedCommunication>;
};

export type QueuedCommunicationsFilters = {
  moduleSerialNumber: Scalars['String']['input'];
};

export type QueuedCommunicationsResponse = {
  __typename?: 'QueuedCommunicationsResponse';
  hasMore: Scalars['Boolean']['output'];
  queuedCommunications: Array<QueuedCommunication>;
};

export enum ServerMessageCommand {
  Br = 'BR',
  Cn = 'CN',
  Cs = 'CS',
  Fa = 'FA',
  Fb = 'FB',
  Rs = 'RS'
}

export type Slot = {
  __typename?: 'Slot';
  _id: Scalars['ID']['output'];
  powerBank?: Maybe<PowerBank>;
};

export type Subscription = {
  __typename?: 'Subscription';
  powerBankUpdated: PowerBank;
};

export type WebhookRequest = {
  __typename?: 'WebhookRequest';
  _id: Scalars['ID']['output'];
  body: Scalars['JSONObject']['output'];
  createdAt: Scalars['Date']['output'];
  finishedAt: Scalars['Date']['output'];
  requestedAt: Scalars['Date']['output'];
  route: Scalars['String']['output'];
  statusCode: Scalars['Int']['output'];
};

export type WebhookRequestResponse = {
  __typename?: 'WebhookRequestResponse';
  hasMore: Scalars['Boolean']['output'];
  webhookRequests: Array<WebhookRequest>;
};

export type WebhookRequestsFilters = {
  afterTimestamp?: InputMaybe<Scalars['Date']['input']>;
  beforeTimestamp?: InputMaybe<Scalars['Date']['input']>;
  moduleSerialNumber: Scalars['String']['input'];
  route: Scalars['String']['input'];
};

export type ApiRequestFragment = { __typename?: 'ApiRequest', _id: string, createdAt: any, requestedAt: any, finishedAt: any, statusCode: number, type: ApiRequestType, origin: ApiRequestOrigin, body: any };

export type MessageFragment = { __typename?: 'Message', _id: string, createdAt: any, sender: MessageSender, topic: string, body: string };

export type ModuleFragment = { __typename?: 'Module', _id: string, serialNumber: string, lastSeenAt: any, firmwareVersion: string, signalIntensity: number, LAC: string, ICCID: string, IMEI: string, CI: string, isOnline: boolean, slots: Array<{ __typename?: 'Slot', _id: string, powerBank?: { __typename?: 'PowerBank', _id: string, serialNumber: string, batteryLevel: number } | null }> };

export type ModuleAuthorizationFragment = { __typename?: 'ModuleAuthorization', _id: string, createdAt: any, moduleSerialNumber: string };

export type ModuleConnectivityStateFragment = { __typename?: 'ModuleConnectivityState', _id: string, createdAt: any, moduleSerialNumber: string, state: ConnectivityState };

export type OngoingCommunicationFragment = { __typename?: 'OngoingCommunication', _id: string, createdAt: any, messageId: number, orderedAt: any, moduleSerialNumber: string, command: ServerMessageCommand, data: Array<string>, shouldAddMessageId: boolean, shouldHaveAnswer: boolean };

export type PowerBankFragment = { __typename?: 'PowerBank', _id: string, serialNumber: string, batteryLevel: number, moduleSerialNumber?: string | null, slotIndex?: number | null, transactionId?: string | null };

export type QueuedCommunicationFragment = { __typename?: 'QueuedCommunication', _id: string, createdAt: any, moduleSerialNumber: string, command: ServerMessageCommand, data: Array<string>, shouldAddMessageId: boolean, shouldHaveAnswer: boolean };

export type WebhookRequestFragment = { __typename?: 'WebhookRequest', _id: string, createdAt: any, requestedAt: any, finishedAt: any, statusCode: number, route: string, body: any };

export type RestartModuleMutationVariables = Exact<{
  serialNumber: Scalars['String']['input'];
}>;


export type RestartModuleMutation = { __typename?: 'Mutation', restartModule: { __typename?: 'ModuleActionResponse', success: boolean, error?: string | null } };

export type UpdateModuleSerialNumberMutationVariables = Exact<{
  serialNumber: Scalars['String']['input'];
  newSerialNumber: Scalars['String']['input'];
}>;


export type UpdateModuleSerialNumberMutation = { __typename?: 'Mutation', updateModuleSerialNumber: { __typename?: 'ModuleActionResponse', success: boolean, error?: string | null } };

export type UpdateModuleMiddlewareUrlMutationVariables = Exact<{
  serialNumber: Scalars['String']['input'];
  url: Scalars['String']['input'];
}>;


export type UpdateModuleMiddlewareUrlMutation = { __typename?: 'Mutation', updateModuleMiddlewareUrl: { __typename?: 'ModuleActionResponse', success: boolean, error?: string | null } };

export type UpdateModuleIntervalsMutationVariables = Exact<{
  serialNumber: Scalars['String']['input'];
  reportingInterval: Scalars['Int']['input'];
  heartbeatInterval: Scalars['Int']['input'];
}>;


export type UpdateModuleIntervalsMutation = { __typename?: 'Mutation', updateModuleIntervals: { __typename?: 'ModuleActionResponse', success: boolean, error?: string | null } };

export type EjectModulePowerBanksMutationVariables = Exact<{
  serialNumber: Scalars['String']['input'];
}>;


export type EjectModulePowerBanksMutation = { __typename?: 'Mutation', ejectModulePowerBanks: { __typename?: 'ModuleActionResponse', success: boolean, error?: string | null } };

export type EjectSinglePowerBankMutationVariables = Exact<{
  moduleSerialNumber: Scalars['String']['input'];
  slotIndex: Scalars['Int']['input'];
}>;


export type EjectSinglePowerBankMutation = { __typename?: 'Mutation', ejectSinglePowerBank: { __typename?: 'ModuleActionResponse', success: boolean, error?: string | null } };

export type CreateModuleAuthorizationMutationVariables = Exact<{
  moduleSerialNumbers?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
}>;


export type CreateModuleAuthorizationMutation = { __typename?: 'Mutation', createModuleAuthorization: { __typename?: 'ModuleAuthorizationResponse', error?: string | null, moduleAuthorizations?: Array<{ __typename?: 'ModuleAuthorization', _id: string, createdAt: any, moduleSerialNumber: string }> | null } };

export type FlushModuleQueuedCommunicationsMutationVariables = Exact<{
  moduleSerialNumber: Scalars['String']['input'];
}>;


export type FlushModuleQueuedCommunicationsMutation = { __typename?: 'Mutation', flushModuleQueuedCommunications: { __typename?: 'QueuedCommunicationsActionResponse', error?: string | null, queuedCommunications: Array<{ __typename?: 'QueuedCommunication', _id: string, createdAt: any, moduleSerialNumber: string, command: ServerMessageCommand, data: Array<string>, shouldAddMessageId: boolean, shouldHaveAnswer: boolean }> } };

export type CancelQueuedCommunicationMutationVariables = Exact<{
  queuedCommunicationId: Scalars['String']['input'];
}>;


export type CancelQueuedCommunicationMutation = { __typename?: 'Mutation', cancelQueuedCommunication: { __typename?: 'QueuedCommunicationsActionResponse', error?: string | null, queuedCommunications: Array<{ __typename?: 'QueuedCommunication', _id: string, createdAt: any, moduleSerialNumber: string, command: ServerMessageCommand, data: Array<string>, shouldAddMessageId: boolean, shouldHaveAnswer: boolean }> } };

export type LoginMutationVariables = Exact<{
  username: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'LoginResponse', error?: string | null, token?: string | null } };

export type GetApiRequestsForChartQueryVariables = Exact<{
  timePeriodInHours: Scalars['Int']['input'];
}>;


export type GetApiRequestsForChartQuery = { __typename?: 'Query', apiRequestsForChart: Array<{ __typename?: 'ApiRequest', _id: string, createdAt: any, requestedAt: any, finishedAt: any }> };

export type GetApiRequestsQueryVariables = Exact<{
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  filters: ApiRequestsFilters;
}>;


export type GetApiRequestsQuery = { __typename?: 'Query', apiRequests: { __typename?: 'ApiRequestResponse', hasMore: boolean, apiRequests: Array<{ __typename?: 'ApiRequest', _id: string, createdAt: any, requestedAt: any, finishedAt: any, statusCode: number, type: ApiRequestType, origin: ApiRequestOrigin, body: any }> } };

export type GetMessagesQueryVariables = Exact<{
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  filters: MessagesFilters;
}>;


export type GetMessagesQuery = { __typename?: 'Query', messages: { __typename?: 'MessagesResponse', hasMore: boolean, messages: Array<{ __typename?: 'Message', _id: string, createdAt: any, sender: MessageSender, topic: string, body: string }> } };

export type GetModulesQueryVariables = Exact<{
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  filters: ModulesFilters;
}>;


export type GetModulesQuery = { __typename?: 'Query', modules: { __typename?: 'ModulesResponse', hasMore: boolean, modules: Array<{ __typename?: 'Module', _id: string, serialNumber: string, lastSeenAt: any, firmwareVersion: string, signalIntensity: number, LAC: string, ICCID: string, IMEI: string, CI: string, isOnline: boolean, slots: Array<{ __typename?: 'Slot', _id: string, powerBank?: { __typename?: 'PowerBank', _id: string, serialNumber: string, batteryLevel: number } | null }> }> } };

export type GetModulesInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetModulesInfoQuery = { __typename?: 'Query', modulesInfo: { __typename?: 'ModulesInfo', totalCount: number, offlineCount: number, potentiallyOfflineCount: number } };

export type GetModuleAuthorizationsQueryVariables = Exact<{
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  filters: ModuleAuthorizationsFilters;
}>;


export type GetModuleAuthorizationsQuery = { __typename?: 'Query', moduleAuthorizations: { __typename?: 'ModuleAuthorizationsResponse', hasMore: boolean, moduleAuthorizations: Array<{ __typename?: 'ModuleAuthorization', _id: string, createdAt: any, moduleSerialNumber: string }> } };

export type GetModuleConnectivityStatesQueryVariables = Exact<{
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  filters: ModuleConnectivityStatesFilters;
}>;


export type GetModuleConnectivityStatesQuery = { __typename?: 'Query', moduleConnectivityStates: { __typename?: 'ModuleConnectivityStatesResponse', hasMore: boolean, moduleConnectivityStates: Array<{ __typename?: 'ModuleConnectivityState', _id: string, createdAt: any, moduleSerialNumber: string, state: ConnectivityState }> } };

export type GetOngoingCommunicationsQueryVariables = Exact<{
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  filters: OngoingCommunicationsFilters;
}>;


export type GetOngoingCommunicationsQuery = { __typename?: 'Query', ongoingCommunications: { __typename?: 'OngoingCommunicationsResponse', hasMore: boolean, ongoingCommunications: Array<{ __typename?: 'OngoingCommunication', _id: string, createdAt: any, messageId: number, orderedAt: any, moduleSerialNumber: string, command: ServerMessageCommand, data: Array<string>, shouldAddMessageId: boolean, shouldHaveAnswer: boolean }> } };

export type GetPowerBanksQueryVariables = Exact<{
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  filters: PowerBanksFilters;
}>;


export type GetPowerBanksQuery = { __typename?: 'Query', powerBanks: { __typename?: 'PowerBanksResponse', hasMore: boolean, powerBanks: Array<{ __typename?: 'PowerBank', _id: string, serialNumber: string, batteryLevel: number, moduleSerialNumber?: string | null, slotIndex?: number | null, transactionId?: string | null }> } };

export type GetPowerBanksInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPowerBanksInfoQuery = { __typename?: 'Query', powerBanksInfo: { __typename?: 'PowerBanksInfo', totalCount: number, loanedCount: number } };

export type GetQueuedCommunicationsQueryVariables = Exact<{
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  filters: QueuedCommunicationsFilters;
}>;


export type GetQueuedCommunicationsQuery = { __typename?: 'Query', queuedCommunications: { __typename?: 'QueuedCommunicationsResponse', hasMore: boolean, queuedCommunications: Array<{ __typename?: 'QueuedCommunication', _id: string, createdAt: any, moduleSerialNumber: string, command: ServerMessageCommand, data: Array<string>, shouldAddMessageId: boolean, shouldHaveAnswer: boolean }> } };

export type GetWebhookRequestsQueryVariables = Exact<{
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  filters: WebhookRequestsFilters;
}>;


export type GetWebhookRequestsQuery = { __typename?: 'Query', webhookRequests: { __typename?: 'WebhookRequestResponse', hasMore: boolean, webhookRequests: Array<{ __typename?: 'WebhookRequest', _id: string, createdAt: any, requestedAt: any, finishedAt: any, statusCode: number, route: string, body: any }> } };

export type OnPowerBankUpdatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnPowerBankUpdatedSubscription = { __typename?: 'Subscription', powerBankUpdated: { __typename?: 'PowerBank', _id: string, serialNumber: string, batteryLevel: number, moduleSerialNumber?: string | null, slotIndex?: number | null, transactionId?: string | null } };

export const ApiRequestFragmentDoc = gql`
    fragment ApiRequest on ApiRequest {
  _id
  createdAt
  requestedAt
  finishedAt
  statusCode
  type
  origin
  body
}
    `;
export const MessageFragmentDoc = gql`
    fragment Message on Message {
  _id
  createdAt
  sender
  topic
  body
}
    `;
export const ModuleFragmentDoc = gql`
    fragment Module on Module {
  _id
  serialNumber
  lastSeenAt
  firmwareVersion
  signalIntensity
  LAC
  ICCID
  IMEI
  CI
  isOnline
  slots {
    _id
    powerBank {
      _id
      serialNumber
      batteryLevel
    }
  }
}
    `;
export const ModuleAuthorizationFragmentDoc = gql`
    fragment ModuleAuthorization on ModuleAuthorization {
  _id
  createdAt
  moduleSerialNumber
}
    `;
export const ModuleConnectivityStateFragmentDoc = gql`
    fragment ModuleConnectivityState on ModuleConnectivityState {
  _id
  createdAt
  moduleSerialNumber
  state
}
    `;
export const OngoingCommunicationFragmentDoc = gql`
    fragment OngoingCommunication on OngoingCommunication {
  _id
  createdAt
  messageId
  orderedAt
  moduleSerialNumber
  command
  data
  shouldAddMessageId
  shouldHaveAnswer
}
    `;
export const PowerBankFragmentDoc = gql`
    fragment PowerBank on PowerBank {
  _id
  serialNumber
  batteryLevel
  moduleSerialNumber
  slotIndex
  transactionId
}
    `;
export const QueuedCommunicationFragmentDoc = gql`
    fragment QueuedCommunication on QueuedCommunication {
  _id
  createdAt
  moduleSerialNumber
  command
  data
  shouldAddMessageId
  shouldHaveAnswer
}
    `;
export const WebhookRequestFragmentDoc = gql`
    fragment WebhookRequest on WebhookRequest {
  _id
  createdAt
  requestedAt
  finishedAt
  statusCode
  route
  body
}
    `;
export const RestartModuleDocument = gql`
    mutation RestartModule($serialNumber: String!) {
  restartModule(serialNumber: $serialNumber) {
    success
    error
  }
}
    `;
export type RestartModuleMutationFn = Apollo.MutationFunction<RestartModuleMutation, RestartModuleMutationVariables>;

/**
 * __useRestartModuleMutation__
 *
 * To run a mutation, you first call `useRestartModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestartModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restartModuleMutation, { data, loading, error }] = useRestartModuleMutation({
 *   variables: {
 *      serialNumber: // value for 'serialNumber'
 *   },
 * });
 */
export function useRestartModuleMutation(baseOptions?: Apollo.MutationHookOptions<RestartModuleMutation, RestartModuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RestartModuleMutation, RestartModuleMutationVariables>(RestartModuleDocument, options);
      }
export type RestartModuleMutationHookResult = ReturnType<typeof useRestartModuleMutation>;
export type RestartModuleMutationResult = Apollo.MutationResult<RestartModuleMutation>;
export type RestartModuleMutationOptions = Apollo.BaseMutationOptions<RestartModuleMutation, RestartModuleMutationVariables>;
export const UpdateModuleSerialNumberDocument = gql`
    mutation UpdateModuleSerialNumber($serialNumber: String!, $newSerialNumber: String!) {
  updateModuleSerialNumber(
    serialNumber: $serialNumber
    newSerialNumber: $newSerialNumber
  ) {
    success
    error
  }
}
    `;
export type UpdateModuleSerialNumberMutationFn = Apollo.MutationFunction<UpdateModuleSerialNumberMutation, UpdateModuleSerialNumberMutationVariables>;

/**
 * __useUpdateModuleSerialNumberMutation__
 *
 * To run a mutation, you first call `useUpdateModuleSerialNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateModuleSerialNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateModuleSerialNumberMutation, { data, loading, error }] = useUpdateModuleSerialNumberMutation({
 *   variables: {
 *      serialNumber: // value for 'serialNumber'
 *      newSerialNumber: // value for 'newSerialNumber'
 *   },
 * });
 */
export function useUpdateModuleSerialNumberMutation(baseOptions?: Apollo.MutationHookOptions<UpdateModuleSerialNumberMutation, UpdateModuleSerialNumberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateModuleSerialNumberMutation, UpdateModuleSerialNumberMutationVariables>(UpdateModuleSerialNumberDocument, options);
      }
export type UpdateModuleSerialNumberMutationHookResult = ReturnType<typeof useUpdateModuleSerialNumberMutation>;
export type UpdateModuleSerialNumberMutationResult = Apollo.MutationResult<UpdateModuleSerialNumberMutation>;
export type UpdateModuleSerialNumberMutationOptions = Apollo.BaseMutationOptions<UpdateModuleSerialNumberMutation, UpdateModuleSerialNumberMutationVariables>;
export const UpdateModuleMiddlewareUrlDocument = gql`
    mutation UpdateModuleMiddlewareUrl($serialNumber: String!, $url: String!) {
  updateModuleMiddlewareUrl(serialNumber: $serialNumber, url: $url) {
    success
    error
  }
}
    `;
export type UpdateModuleMiddlewareUrlMutationFn = Apollo.MutationFunction<UpdateModuleMiddlewareUrlMutation, UpdateModuleMiddlewareUrlMutationVariables>;

/**
 * __useUpdateModuleMiddlewareUrlMutation__
 *
 * To run a mutation, you first call `useUpdateModuleMiddlewareUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateModuleMiddlewareUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateModuleMiddlewareUrlMutation, { data, loading, error }] = useUpdateModuleMiddlewareUrlMutation({
 *   variables: {
 *      serialNumber: // value for 'serialNumber'
 *      url: // value for 'url'
 *   },
 * });
 */
export function useUpdateModuleMiddlewareUrlMutation(baseOptions?: Apollo.MutationHookOptions<UpdateModuleMiddlewareUrlMutation, UpdateModuleMiddlewareUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateModuleMiddlewareUrlMutation, UpdateModuleMiddlewareUrlMutationVariables>(UpdateModuleMiddlewareUrlDocument, options);
      }
export type UpdateModuleMiddlewareUrlMutationHookResult = ReturnType<typeof useUpdateModuleMiddlewareUrlMutation>;
export type UpdateModuleMiddlewareUrlMutationResult = Apollo.MutationResult<UpdateModuleMiddlewareUrlMutation>;
export type UpdateModuleMiddlewareUrlMutationOptions = Apollo.BaseMutationOptions<UpdateModuleMiddlewareUrlMutation, UpdateModuleMiddlewareUrlMutationVariables>;
export const UpdateModuleIntervalsDocument = gql`
    mutation UpdateModuleIntervals($serialNumber: String!, $reportingInterval: Int!, $heartbeatInterval: Int!) {
  updateModuleIntervals(
    serialNumber: $serialNumber
    reportingInterval: $reportingInterval
    heartbeatInterval: $heartbeatInterval
  ) {
    success
    error
  }
}
    `;
export type UpdateModuleIntervalsMutationFn = Apollo.MutationFunction<UpdateModuleIntervalsMutation, UpdateModuleIntervalsMutationVariables>;

/**
 * __useUpdateModuleIntervalsMutation__
 *
 * To run a mutation, you first call `useUpdateModuleIntervalsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateModuleIntervalsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateModuleIntervalsMutation, { data, loading, error }] = useUpdateModuleIntervalsMutation({
 *   variables: {
 *      serialNumber: // value for 'serialNumber'
 *      reportingInterval: // value for 'reportingInterval'
 *      heartbeatInterval: // value for 'heartbeatInterval'
 *   },
 * });
 */
export function useUpdateModuleIntervalsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateModuleIntervalsMutation, UpdateModuleIntervalsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateModuleIntervalsMutation, UpdateModuleIntervalsMutationVariables>(UpdateModuleIntervalsDocument, options);
      }
export type UpdateModuleIntervalsMutationHookResult = ReturnType<typeof useUpdateModuleIntervalsMutation>;
export type UpdateModuleIntervalsMutationResult = Apollo.MutationResult<UpdateModuleIntervalsMutation>;
export type UpdateModuleIntervalsMutationOptions = Apollo.BaseMutationOptions<UpdateModuleIntervalsMutation, UpdateModuleIntervalsMutationVariables>;
export const EjectModulePowerBanksDocument = gql`
    mutation EjectModulePowerBanks($serialNumber: String!) {
  ejectModulePowerBanks(serialNumber: $serialNumber) {
    success
    error
  }
}
    `;
export type EjectModulePowerBanksMutationFn = Apollo.MutationFunction<EjectModulePowerBanksMutation, EjectModulePowerBanksMutationVariables>;

/**
 * __useEjectModulePowerBanksMutation__
 *
 * To run a mutation, you first call `useEjectModulePowerBanksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEjectModulePowerBanksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ejectModulePowerBanksMutation, { data, loading, error }] = useEjectModulePowerBanksMutation({
 *   variables: {
 *      serialNumber: // value for 'serialNumber'
 *   },
 * });
 */
export function useEjectModulePowerBanksMutation(baseOptions?: Apollo.MutationHookOptions<EjectModulePowerBanksMutation, EjectModulePowerBanksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EjectModulePowerBanksMutation, EjectModulePowerBanksMutationVariables>(EjectModulePowerBanksDocument, options);
      }
export type EjectModulePowerBanksMutationHookResult = ReturnType<typeof useEjectModulePowerBanksMutation>;
export type EjectModulePowerBanksMutationResult = Apollo.MutationResult<EjectModulePowerBanksMutation>;
export type EjectModulePowerBanksMutationOptions = Apollo.BaseMutationOptions<EjectModulePowerBanksMutation, EjectModulePowerBanksMutationVariables>;
export const EjectSinglePowerBankDocument = gql`
    mutation EjectSinglePowerBank($moduleSerialNumber: String!, $slotIndex: Int!) {
  ejectSinglePowerBank(
    moduleSerialNumber: $moduleSerialNumber
    slotIndex: $slotIndex
  ) {
    success
    error
  }
}
    `;
export type EjectSinglePowerBankMutationFn = Apollo.MutationFunction<EjectSinglePowerBankMutation, EjectSinglePowerBankMutationVariables>;

/**
 * __useEjectSinglePowerBankMutation__
 *
 * To run a mutation, you first call `useEjectSinglePowerBankMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEjectSinglePowerBankMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ejectSinglePowerBankMutation, { data, loading, error }] = useEjectSinglePowerBankMutation({
 *   variables: {
 *      moduleSerialNumber: // value for 'moduleSerialNumber'
 *      slotIndex: // value for 'slotIndex'
 *   },
 * });
 */
export function useEjectSinglePowerBankMutation(baseOptions?: Apollo.MutationHookOptions<EjectSinglePowerBankMutation, EjectSinglePowerBankMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EjectSinglePowerBankMutation, EjectSinglePowerBankMutationVariables>(EjectSinglePowerBankDocument, options);
      }
export type EjectSinglePowerBankMutationHookResult = ReturnType<typeof useEjectSinglePowerBankMutation>;
export type EjectSinglePowerBankMutationResult = Apollo.MutationResult<EjectSinglePowerBankMutation>;
export type EjectSinglePowerBankMutationOptions = Apollo.BaseMutationOptions<EjectSinglePowerBankMutation, EjectSinglePowerBankMutationVariables>;
export const CreateModuleAuthorizationDocument = gql`
    mutation CreateModuleAuthorization($moduleSerialNumbers: [String!]) {
  createModuleAuthorization(moduleSerialNumbers: $moduleSerialNumbers) {
    moduleAuthorizations {
      ...ModuleAuthorization
    }
    error
  }
}
    ${ModuleAuthorizationFragmentDoc}`;
export type CreateModuleAuthorizationMutationFn = Apollo.MutationFunction<CreateModuleAuthorizationMutation, CreateModuleAuthorizationMutationVariables>;

/**
 * __useCreateModuleAuthorizationMutation__
 *
 * To run a mutation, you first call `useCreateModuleAuthorizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateModuleAuthorizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createModuleAuthorizationMutation, { data, loading, error }] = useCreateModuleAuthorizationMutation({
 *   variables: {
 *      moduleSerialNumbers: // value for 'moduleSerialNumbers'
 *   },
 * });
 */
export function useCreateModuleAuthorizationMutation(baseOptions?: Apollo.MutationHookOptions<CreateModuleAuthorizationMutation, CreateModuleAuthorizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateModuleAuthorizationMutation, CreateModuleAuthorizationMutationVariables>(CreateModuleAuthorizationDocument, options);
      }
export type CreateModuleAuthorizationMutationHookResult = ReturnType<typeof useCreateModuleAuthorizationMutation>;
export type CreateModuleAuthorizationMutationResult = Apollo.MutationResult<CreateModuleAuthorizationMutation>;
export type CreateModuleAuthorizationMutationOptions = Apollo.BaseMutationOptions<CreateModuleAuthorizationMutation, CreateModuleAuthorizationMutationVariables>;
export const FlushModuleQueuedCommunicationsDocument = gql`
    mutation FlushModuleQueuedCommunications($moduleSerialNumber: String!) {
  flushModuleQueuedCommunications(moduleSerialNumber: $moduleSerialNumber) {
    error
    queuedCommunications {
      ...QueuedCommunication
    }
  }
}
    ${QueuedCommunicationFragmentDoc}`;
export type FlushModuleQueuedCommunicationsMutationFn = Apollo.MutationFunction<FlushModuleQueuedCommunicationsMutation, FlushModuleQueuedCommunicationsMutationVariables>;

/**
 * __useFlushModuleQueuedCommunicationsMutation__
 *
 * To run a mutation, you first call `useFlushModuleQueuedCommunicationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFlushModuleQueuedCommunicationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [flushModuleQueuedCommunicationsMutation, { data, loading, error }] = useFlushModuleQueuedCommunicationsMutation({
 *   variables: {
 *      moduleSerialNumber: // value for 'moduleSerialNumber'
 *   },
 * });
 */
export function useFlushModuleQueuedCommunicationsMutation(baseOptions?: Apollo.MutationHookOptions<FlushModuleQueuedCommunicationsMutation, FlushModuleQueuedCommunicationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FlushModuleQueuedCommunicationsMutation, FlushModuleQueuedCommunicationsMutationVariables>(FlushModuleQueuedCommunicationsDocument, options);
      }
export type FlushModuleQueuedCommunicationsMutationHookResult = ReturnType<typeof useFlushModuleQueuedCommunicationsMutation>;
export type FlushModuleQueuedCommunicationsMutationResult = Apollo.MutationResult<FlushModuleQueuedCommunicationsMutation>;
export type FlushModuleQueuedCommunicationsMutationOptions = Apollo.BaseMutationOptions<FlushModuleQueuedCommunicationsMutation, FlushModuleQueuedCommunicationsMutationVariables>;
export const CancelQueuedCommunicationDocument = gql`
    mutation CancelQueuedCommunication($queuedCommunicationId: String!) {
  cancelQueuedCommunication(queuedCommunicationId: $queuedCommunicationId) {
    error
    queuedCommunications {
      ...QueuedCommunication
    }
  }
}
    ${QueuedCommunicationFragmentDoc}`;
export type CancelQueuedCommunicationMutationFn = Apollo.MutationFunction<CancelQueuedCommunicationMutation, CancelQueuedCommunicationMutationVariables>;

/**
 * __useCancelQueuedCommunicationMutation__
 *
 * To run a mutation, you first call `useCancelQueuedCommunicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelQueuedCommunicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelQueuedCommunicationMutation, { data, loading, error }] = useCancelQueuedCommunicationMutation({
 *   variables: {
 *      queuedCommunicationId: // value for 'queuedCommunicationId'
 *   },
 * });
 */
export function useCancelQueuedCommunicationMutation(baseOptions?: Apollo.MutationHookOptions<CancelQueuedCommunicationMutation, CancelQueuedCommunicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelQueuedCommunicationMutation, CancelQueuedCommunicationMutationVariables>(CancelQueuedCommunicationDocument, options);
      }
export type CancelQueuedCommunicationMutationHookResult = ReturnType<typeof useCancelQueuedCommunicationMutation>;
export type CancelQueuedCommunicationMutationResult = Apollo.MutationResult<CancelQueuedCommunicationMutation>;
export type CancelQueuedCommunicationMutationOptions = Apollo.BaseMutationOptions<CancelQueuedCommunicationMutation, CancelQueuedCommunicationMutationVariables>;
export const LoginDocument = gql`
    mutation Login($username: String!, $password: String!) {
  login(username: $username, password: $password) {
    error
    token
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const GetApiRequestsForChartDocument = gql`
    query GetApiRequestsForChart($timePeriodInHours: Int!) {
  apiRequestsForChart(timePeriodInHours: $timePeriodInHours) {
    _id
    createdAt
    requestedAt
    finishedAt
  }
}
    `;

/**
 * __useGetApiRequestsForChartQuery__
 *
 * To run a query within a React component, call `useGetApiRequestsForChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApiRequestsForChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApiRequestsForChartQuery({
 *   variables: {
 *      timePeriodInHours: // value for 'timePeriodInHours'
 *   },
 * });
 */
export function useGetApiRequestsForChartQuery(baseOptions: Apollo.QueryHookOptions<GetApiRequestsForChartQuery, GetApiRequestsForChartQueryVariables> & ({ variables: GetApiRequestsForChartQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetApiRequestsForChartQuery, GetApiRequestsForChartQueryVariables>(GetApiRequestsForChartDocument, options);
      }
export function useGetApiRequestsForChartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApiRequestsForChartQuery, GetApiRequestsForChartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetApiRequestsForChartQuery, GetApiRequestsForChartQueryVariables>(GetApiRequestsForChartDocument, options);
        }
export function useGetApiRequestsForChartSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetApiRequestsForChartQuery, GetApiRequestsForChartQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetApiRequestsForChartQuery, GetApiRequestsForChartQueryVariables>(GetApiRequestsForChartDocument, options);
        }
export type GetApiRequestsForChartQueryHookResult = ReturnType<typeof useGetApiRequestsForChartQuery>;
export type GetApiRequestsForChartLazyQueryHookResult = ReturnType<typeof useGetApiRequestsForChartLazyQuery>;
export type GetApiRequestsForChartSuspenseQueryHookResult = ReturnType<typeof useGetApiRequestsForChartSuspenseQuery>;
export type GetApiRequestsForChartQueryResult = Apollo.QueryResult<GetApiRequestsForChartQuery, GetApiRequestsForChartQueryVariables>;
export const GetApiRequestsDocument = gql`
    query GetApiRequests($limit: Int!, $offset: Int!, $filters: ApiRequestsFilters!) {
  apiRequests(limit: $limit, offset: $offset, filters: $filters) {
    apiRequests {
      ...ApiRequest
    }
    hasMore
  }
}
    ${ApiRequestFragmentDoc}`;

/**
 * __useGetApiRequestsQuery__
 *
 * To run a query within a React component, call `useGetApiRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApiRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApiRequestsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetApiRequestsQuery(baseOptions: Apollo.QueryHookOptions<GetApiRequestsQuery, GetApiRequestsQueryVariables> & ({ variables: GetApiRequestsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetApiRequestsQuery, GetApiRequestsQueryVariables>(GetApiRequestsDocument, options);
      }
export function useGetApiRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApiRequestsQuery, GetApiRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetApiRequestsQuery, GetApiRequestsQueryVariables>(GetApiRequestsDocument, options);
        }
export function useGetApiRequestsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetApiRequestsQuery, GetApiRequestsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetApiRequestsQuery, GetApiRequestsQueryVariables>(GetApiRequestsDocument, options);
        }
export type GetApiRequestsQueryHookResult = ReturnType<typeof useGetApiRequestsQuery>;
export type GetApiRequestsLazyQueryHookResult = ReturnType<typeof useGetApiRequestsLazyQuery>;
export type GetApiRequestsSuspenseQueryHookResult = ReturnType<typeof useGetApiRequestsSuspenseQuery>;
export type GetApiRequestsQueryResult = Apollo.QueryResult<GetApiRequestsQuery, GetApiRequestsQueryVariables>;
export const GetMessagesDocument = gql`
    query GetMessages($limit: Int!, $offset: Int!, $filters: MessagesFilters!) {
  messages(limit: $limit, offset: $offset, filters: $filters) {
    messages {
      ...Message
    }
    hasMore
  }
}
    ${MessageFragmentDoc}`;

/**
 * __useGetMessagesQuery__
 *
 * To run a query within a React component, call `useGetMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMessagesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetMessagesQuery(baseOptions: Apollo.QueryHookOptions<GetMessagesQuery, GetMessagesQueryVariables> & ({ variables: GetMessagesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMessagesQuery, GetMessagesQueryVariables>(GetMessagesDocument, options);
      }
export function useGetMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMessagesQuery, GetMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMessagesQuery, GetMessagesQueryVariables>(GetMessagesDocument, options);
        }
export function useGetMessagesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetMessagesQuery, GetMessagesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMessagesQuery, GetMessagesQueryVariables>(GetMessagesDocument, options);
        }
export type GetMessagesQueryHookResult = ReturnType<typeof useGetMessagesQuery>;
export type GetMessagesLazyQueryHookResult = ReturnType<typeof useGetMessagesLazyQuery>;
export type GetMessagesSuspenseQueryHookResult = ReturnType<typeof useGetMessagesSuspenseQuery>;
export type GetMessagesQueryResult = Apollo.QueryResult<GetMessagesQuery, GetMessagesQueryVariables>;
export const GetModulesDocument = gql`
    query GetModules($limit: Int!, $offset: Int!, $filters: ModulesFilters!) {
  modules(limit: $limit, offset: $offset, filters: $filters) {
    modules {
      ...Module
    }
    hasMore
  }
}
    ${ModuleFragmentDoc}`;

/**
 * __useGetModulesQuery__
 *
 * To run a query within a React component, call `useGetModulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModulesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetModulesQuery(baseOptions: Apollo.QueryHookOptions<GetModulesQuery, GetModulesQueryVariables> & ({ variables: GetModulesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetModulesQuery, GetModulesQueryVariables>(GetModulesDocument, options);
      }
export function useGetModulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetModulesQuery, GetModulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetModulesQuery, GetModulesQueryVariables>(GetModulesDocument, options);
        }
export function useGetModulesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetModulesQuery, GetModulesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetModulesQuery, GetModulesQueryVariables>(GetModulesDocument, options);
        }
export type GetModulesQueryHookResult = ReturnType<typeof useGetModulesQuery>;
export type GetModulesLazyQueryHookResult = ReturnType<typeof useGetModulesLazyQuery>;
export type GetModulesSuspenseQueryHookResult = ReturnType<typeof useGetModulesSuspenseQuery>;
export type GetModulesQueryResult = Apollo.QueryResult<GetModulesQuery, GetModulesQueryVariables>;
export const GetModulesInfoDocument = gql`
    query GetModulesInfo {
  modulesInfo {
    totalCount
    offlineCount
    potentiallyOfflineCount
  }
}
    `;

/**
 * __useGetModulesInfoQuery__
 *
 * To run a query within a React component, call `useGetModulesInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModulesInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModulesInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetModulesInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetModulesInfoQuery, GetModulesInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetModulesInfoQuery, GetModulesInfoQueryVariables>(GetModulesInfoDocument, options);
      }
export function useGetModulesInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetModulesInfoQuery, GetModulesInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetModulesInfoQuery, GetModulesInfoQueryVariables>(GetModulesInfoDocument, options);
        }
export function useGetModulesInfoSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetModulesInfoQuery, GetModulesInfoQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetModulesInfoQuery, GetModulesInfoQueryVariables>(GetModulesInfoDocument, options);
        }
export type GetModulesInfoQueryHookResult = ReturnType<typeof useGetModulesInfoQuery>;
export type GetModulesInfoLazyQueryHookResult = ReturnType<typeof useGetModulesInfoLazyQuery>;
export type GetModulesInfoSuspenseQueryHookResult = ReturnType<typeof useGetModulesInfoSuspenseQuery>;
export type GetModulesInfoQueryResult = Apollo.QueryResult<GetModulesInfoQuery, GetModulesInfoQueryVariables>;
export const GetModuleAuthorizationsDocument = gql`
    query GetModuleAuthorizations($limit: Int!, $offset: Int!, $filters: ModuleAuthorizationsFilters!) {
  moduleAuthorizations(limit: $limit, offset: $offset, filters: $filters) {
    moduleAuthorizations {
      ...ModuleAuthorization
    }
    hasMore
  }
}
    ${ModuleAuthorizationFragmentDoc}`;

/**
 * __useGetModuleAuthorizationsQuery__
 *
 * To run a query within a React component, call `useGetModuleAuthorizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModuleAuthorizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModuleAuthorizationsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetModuleAuthorizationsQuery(baseOptions: Apollo.QueryHookOptions<GetModuleAuthorizationsQuery, GetModuleAuthorizationsQueryVariables> & ({ variables: GetModuleAuthorizationsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetModuleAuthorizationsQuery, GetModuleAuthorizationsQueryVariables>(GetModuleAuthorizationsDocument, options);
      }
export function useGetModuleAuthorizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetModuleAuthorizationsQuery, GetModuleAuthorizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetModuleAuthorizationsQuery, GetModuleAuthorizationsQueryVariables>(GetModuleAuthorizationsDocument, options);
        }
export function useGetModuleAuthorizationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetModuleAuthorizationsQuery, GetModuleAuthorizationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetModuleAuthorizationsQuery, GetModuleAuthorizationsQueryVariables>(GetModuleAuthorizationsDocument, options);
        }
export type GetModuleAuthorizationsQueryHookResult = ReturnType<typeof useGetModuleAuthorizationsQuery>;
export type GetModuleAuthorizationsLazyQueryHookResult = ReturnType<typeof useGetModuleAuthorizationsLazyQuery>;
export type GetModuleAuthorizationsSuspenseQueryHookResult = ReturnType<typeof useGetModuleAuthorizationsSuspenseQuery>;
export type GetModuleAuthorizationsQueryResult = Apollo.QueryResult<GetModuleAuthorizationsQuery, GetModuleAuthorizationsQueryVariables>;
export const GetModuleConnectivityStatesDocument = gql`
    query GetModuleConnectivityStates($limit: Int!, $offset: Int!, $filters: ModuleConnectivityStatesFilters!) {
  moduleConnectivityStates(limit: $limit, offset: $offset, filters: $filters) {
    moduleConnectivityStates {
      ...ModuleConnectivityState
    }
    hasMore
  }
}
    ${ModuleConnectivityStateFragmentDoc}`;

/**
 * __useGetModuleConnectivityStatesQuery__
 *
 * To run a query within a React component, call `useGetModuleConnectivityStatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModuleConnectivityStatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModuleConnectivityStatesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetModuleConnectivityStatesQuery(baseOptions: Apollo.QueryHookOptions<GetModuleConnectivityStatesQuery, GetModuleConnectivityStatesQueryVariables> & ({ variables: GetModuleConnectivityStatesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetModuleConnectivityStatesQuery, GetModuleConnectivityStatesQueryVariables>(GetModuleConnectivityStatesDocument, options);
      }
export function useGetModuleConnectivityStatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetModuleConnectivityStatesQuery, GetModuleConnectivityStatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetModuleConnectivityStatesQuery, GetModuleConnectivityStatesQueryVariables>(GetModuleConnectivityStatesDocument, options);
        }
export function useGetModuleConnectivityStatesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetModuleConnectivityStatesQuery, GetModuleConnectivityStatesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetModuleConnectivityStatesQuery, GetModuleConnectivityStatesQueryVariables>(GetModuleConnectivityStatesDocument, options);
        }
export type GetModuleConnectivityStatesQueryHookResult = ReturnType<typeof useGetModuleConnectivityStatesQuery>;
export type GetModuleConnectivityStatesLazyQueryHookResult = ReturnType<typeof useGetModuleConnectivityStatesLazyQuery>;
export type GetModuleConnectivityStatesSuspenseQueryHookResult = ReturnType<typeof useGetModuleConnectivityStatesSuspenseQuery>;
export type GetModuleConnectivityStatesQueryResult = Apollo.QueryResult<GetModuleConnectivityStatesQuery, GetModuleConnectivityStatesQueryVariables>;
export const GetOngoingCommunicationsDocument = gql`
    query GetOngoingCommunications($limit: Int!, $offset: Int!, $filters: OngoingCommunicationsFilters!) {
  ongoingCommunications(limit: $limit, offset: $offset, filters: $filters) {
    ongoingCommunications {
      ...OngoingCommunication
    }
    hasMore
  }
}
    ${OngoingCommunicationFragmentDoc}`;

/**
 * __useGetOngoingCommunicationsQuery__
 *
 * To run a query within a React component, call `useGetOngoingCommunicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOngoingCommunicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOngoingCommunicationsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetOngoingCommunicationsQuery(baseOptions: Apollo.QueryHookOptions<GetOngoingCommunicationsQuery, GetOngoingCommunicationsQueryVariables> & ({ variables: GetOngoingCommunicationsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOngoingCommunicationsQuery, GetOngoingCommunicationsQueryVariables>(GetOngoingCommunicationsDocument, options);
      }
export function useGetOngoingCommunicationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOngoingCommunicationsQuery, GetOngoingCommunicationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOngoingCommunicationsQuery, GetOngoingCommunicationsQueryVariables>(GetOngoingCommunicationsDocument, options);
        }
export function useGetOngoingCommunicationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetOngoingCommunicationsQuery, GetOngoingCommunicationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetOngoingCommunicationsQuery, GetOngoingCommunicationsQueryVariables>(GetOngoingCommunicationsDocument, options);
        }
export type GetOngoingCommunicationsQueryHookResult = ReturnType<typeof useGetOngoingCommunicationsQuery>;
export type GetOngoingCommunicationsLazyQueryHookResult = ReturnType<typeof useGetOngoingCommunicationsLazyQuery>;
export type GetOngoingCommunicationsSuspenseQueryHookResult = ReturnType<typeof useGetOngoingCommunicationsSuspenseQuery>;
export type GetOngoingCommunicationsQueryResult = Apollo.QueryResult<GetOngoingCommunicationsQuery, GetOngoingCommunicationsQueryVariables>;
export const GetPowerBanksDocument = gql`
    query GetPowerBanks($limit: Int!, $offset: Int!, $filters: PowerBanksFilters!) {
  powerBanks(limit: $limit, offset: $offset, filters: $filters) {
    powerBanks {
      ...PowerBank
    }
    hasMore
  }
}
    ${PowerBankFragmentDoc}`;

/**
 * __useGetPowerBanksQuery__
 *
 * To run a query within a React component, call `useGetPowerBanksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPowerBanksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPowerBanksQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetPowerBanksQuery(baseOptions: Apollo.QueryHookOptions<GetPowerBanksQuery, GetPowerBanksQueryVariables> & ({ variables: GetPowerBanksQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPowerBanksQuery, GetPowerBanksQueryVariables>(GetPowerBanksDocument, options);
      }
export function useGetPowerBanksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPowerBanksQuery, GetPowerBanksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPowerBanksQuery, GetPowerBanksQueryVariables>(GetPowerBanksDocument, options);
        }
export function useGetPowerBanksSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPowerBanksQuery, GetPowerBanksQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPowerBanksQuery, GetPowerBanksQueryVariables>(GetPowerBanksDocument, options);
        }
export type GetPowerBanksQueryHookResult = ReturnType<typeof useGetPowerBanksQuery>;
export type GetPowerBanksLazyQueryHookResult = ReturnType<typeof useGetPowerBanksLazyQuery>;
export type GetPowerBanksSuspenseQueryHookResult = ReturnType<typeof useGetPowerBanksSuspenseQuery>;
export type GetPowerBanksQueryResult = Apollo.QueryResult<GetPowerBanksQuery, GetPowerBanksQueryVariables>;
export const GetPowerBanksInfoDocument = gql`
    query GetPowerBanksInfo {
  powerBanksInfo {
    totalCount
    loanedCount
  }
}
    `;

/**
 * __useGetPowerBanksInfoQuery__
 *
 * To run a query within a React component, call `useGetPowerBanksInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPowerBanksInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPowerBanksInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPowerBanksInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetPowerBanksInfoQuery, GetPowerBanksInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPowerBanksInfoQuery, GetPowerBanksInfoQueryVariables>(GetPowerBanksInfoDocument, options);
      }
export function useGetPowerBanksInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPowerBanksInfoQuery, GetPowerBanksInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPowerBanksInfoQuery, GetPowerBanksInfoQueryVariables>(GetPowerBanksInfoDocument, options);
        }
export function useGetPowerBanksInfoSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPowerBanksInfoQuery, GetPowerBanksInfoQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPowerBanksInfoQuery, GetPowerBanksInfoQueryVariables>(GetPowerBanksInfoDocument, options);
        }
export type GetPowerBanksInfoQueryHookResult = ReturnType<typeof useGetPowerBanksInfoQuery>;
export type GetPowerBanksInfoLazyQueryHookResult = ReturnType<typeof useGetPowerBanksInfoLazyQuery>;
export type GetPowerBanksInfoSuspenseQueryHookResult = ReturnType<typeof useGetPowerBanksInfoSuspenseQuery>;
export type GetPowerBanksInfoQueryResult = Apollo.QueryResult<GetPowerBanksInfoQuery, GetPowerBanksInfoQueryVariables>;
export const GetQueuedCommunicationsDocument = gql`
    query GetQueuedCommunications($limit: Int!, $offset: Int!, $filters: QueuedCommunicationsFilters!) {
  queuedCommunications(limit: $limit, offset: $offset, filters: $filters) {
    queuedCommunications {
      ...QueuedCommunication
    }
    hasMore
  }
}
    ${QueuedCommunicationFragmentDoc}`;

/**
 * __useGetQueuedCommunicationsQuery__
 *
 * To run a query within a React component, call `useGetQueuedCommunicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQueuedCommunicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQueuedCommunicationsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetQueuedCommunicationsQuery(baseOptions: Apollo.QueryHookOptions<GetQueuedCommunicationsQuery, GetQueuedCommunicationsQueryVariables> & ({ variables: GetQueuedCommunicationsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQueuedCommunicationsQuery, GetQueuedCommunicationsQueryVariables>(GetQueuedCommunicationsDocument, options);
      }
export function useGetQueuedCommunicationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQueuedCommunicationsQuery, GetQueuedCommunicationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQueuedCommunicationsQuery, GetQueuedCommunicationsQueryVariables>(GetQueuedCommunicationsDocument, options);
        }
export function useGetQueuedCommunicationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetQueuedCommunicationsQuery, GetQueuedCommunicationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetQueuedCommunicationsQuery, GetQueuedCommunicationsQueryVariables>(GetQueuedCommunicationsDocument, options);
        }
export type GetQueuedCommunicationsQueryHookResult = ReturnType<typeof useGetQueuedCommunicationsQuery>;
export type GetQueuedCommunicationsLazyQueryHookResult = ReturnType<typeof useGetQueuedCommunicationsLazyQuery>;
export type GetQueuedCommunicationsSuspenseQueryHookResult = ReturnType<typeof useGetQueuedCommunicationsSuspenseQuery>;
export type GetQueuedCommunicationsQueryResult = Apollo.QueryResult<GetQueuedCommunicationsQuery, GetQueuedCommunicationsQueryVariables>;
export const GetWebhookRequestsDocument = gql`
    query GetWebhookRequests($limit: Int!, $offset: Int!, $filters: WebhookRequestsFilters!) {
  webhookRequests(limit: $limit, offset: $offset, filters: $filters) {
    webhookRequests {
      ...WebhookRequest
    }
    hasMore
  }
}
    ${WebhookRequestFragmentDoc}`;

/**
 * __useGetWebhookRequestsQuery__
 *
 * To run a query within a React component, call `useGetWebhookRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWebhookRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWebhookRequestsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetWebhookRequestsQuery(baseOptions: Apollo.QueryHookOptions<GetWebhookRequestsQuery, GetWebhookRequestsQueryVariables> & ({ variables: GetWebhookRequestsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWebhookRequestsQuery, GetWebhookRequestsQueryVariables>(GetWebhookRequestsDocument, options);
      }
export function useGetWebhookRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWebhookRequestsQuery, GetWebhookRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWebhookRequestsQuery, GetWebhookRequestsQueryVariables>(GetWebhookRequestsDocument, options);
        }
export function useGetWebhookRequestsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetWebhookRequestsQuery, GetWebhookRequestsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetWebhookRequestsQuery, GetWebhookRequestsQueryVariables>(GetWebhookRequestsDocument, options);
        }
export type GetWebhookRequestsQueryHookResult = ReturnType<typeof useGetWebhookRequestsQuery>;
export type GetWebhookRequestsLazyQueryHookResult = ReturnType<typeof useGetWebhookRequestsLazyQuery>;
export type GetWebhookRequestsSuspenseQueryHookResult = ReturnType<typeof useGetWebhookRequestsSuspenseQuery>;
export type GetWebhookRequestsQueryResult = Apollo.QueryResult<GetWebhookRequestsQuery, GetWebhookRequestsQueryVariables>;
export const OnPowerBankUpdatedDocument = gql`
    subscription OnPowerBankUpdated {
  powerBankUpdated {
    ...PowerBank
  }
}
    ${PowerBankFragmentDoc}`;

/**
 * __useOnPowerBankUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnPowerBankUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnPowerBankUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnPowerBankUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnPowerBankUpdatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnPowerBankUpdatedSubscription, OnPowerBankUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnPowerBankUpdatedSubscription, OnPowerBankUpdatedSubscriptionVariables>(OnPowerBankUpdatedDocument, options);
      }
export type OnPowerBankUpdatedSubscriptionHookResult = ReturnType<typeof useOnPowerBankUpdatedSubscription>;
export type OnPowerBankUpdatedSubscriptionResult = Apollo.SubscriptionResult<OnPowerBankUpdatedSubscription>;