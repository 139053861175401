import styled from "styled-components";
import { WebhookRequestsList } from "./components/WebhookRequestsList";
import { Title } from "../../design-system/atoms/Title";
import { Input } from "../../design-system/atoms/Input";
import { useEffect, useState } from "react";
import { DatetimePicker } from "../../design-system/atoms/DatetimePicker";
import { useSearchParams } from "react-router-dom";
import { updateSearchParam } from "../../utils/searchParams";

export const WebhookRequests = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [filters, setFilters] = useState<{
    moduleSerialNumber: string;
    route: string;
    afterTimestamp: number | null;
    beforeTimestamp: number | null;
  }>({
    moduleSerialNumber: searchParams.get("moduleSerialNumber") || "",
    route: searchParams.get("route") || "",
    afterTimestamp: searchParams.get("afterTimestamp")
      ? Number(searchParams.get("afterTimestamp"))
      : null,
    beforeTimestamp: searchParams.get("beforeTimestamp")
      ? Number(searchParams.get("beforeTimestamp"))
      : null,
  });

  useEffect(() => {
    const filterKeys = [
      { key: "moduleSerialNumber", value: filters.moduleSerialNumber },
      { key: "route", value: filters.route },
      { key: "afterTimestamp", value: filters.afterTimestamp },
      { key: "beforeTimestamp", value: filters.beforeTimestamp },
    ];

    filterKeys.forEach(({ key, value }) => {
      updateSearchParam(searchParams, key, value);
    });

    setSearchParams(searchParams);
  }, [filters, searchParams, setSearchParams]);

  return (
    <Container>
      <Title>Webhook requests</Title>
      <FiltersContainer>
        <Input
          label="Module Serial Number"
          placeholder="12345678"
          value={filters.moduleSerialNumber}
          onChange={(value: string) =>
            setFilters({ ...filters, moduleSerialNumber: value })
          }
        />
        <Input
          label="Route"
          placeholder="/confirmation/power-bank/returned"
          value={filters.route}
          onChange={(value: string) => setFilters({ ...filters, route: value })}
        />
        <DatetimePicker
          label="After date"
          value={filters.afterTimestamp}
          onChange={(value) =>
            setFilters({
              ...filters,
              afterTimestamp: value,
            })
          }
        />
        <DatetimePicker
          label="Before date"
          value={filters.beforeTimestamp}
          onChange={(value) =>
            setFilters({
              ...filters,
              beforeTimestamp: value,
            })
          }
        />
      </FiltersContainer>
      <WebhookRequestsList filters={filters} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 20px;
  overflow: hidden;
  padding: 15px;
`;

const FiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;
