export function updateSearchParam(
  searchParams: URLSearchParams,
  key: string,
  value: any
) {
  if (typeof value === "boolean") {
    searchParams.set(key, value.toString());
  } else if (value && (Array.isArray(value) ? value.length > 0 : true)) {
    searchParams.set(
      key,
      Array.isArray(value) ? JSON.stringify(value) : value.toString()
    );
  } else {
    searchParams.delete(key);
  }
}

export function extractArrayFromSearchParams<T>(
  searchParams: URLSearchParams
): T[] {
  const commands = searchParams.get("commands");
  if (!commands) {
    return [];
  }

  try {
    return JSON.parse(commands);
  } catch (error) {
    return [];
  }
}
