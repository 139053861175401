import styled from "styled-components";
import { PowerBanksList } from "./components/PowerBanksList";
import { Title } from "../../design-system/atoms/Title";
import { Input } from "../../design-system/atoms/Input";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { updateSearchParam } from "../../utils/searchParams";
import { Select } from "../../design-system/atoms/Select";
import { PowerBanksInfo } from "./components/PowerBanksInfo";

const transactionStatusOptions = [
  {
    label: "Is in transaction",
    value: true,
  },
  {
    label: "Is on a module",
    value: false,
  },
];

export const PowerBanks = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [filters, setFilters] = useState({
    powerBankSerialNumber: searchParams.get("powerBankSerialNumber") || "",
    transactionId: searchParams.get("transactionId") || "",
    isInTransaction: searchParams.get("isInTransaction")
      ? Boolean(searchParams.get("isInTransaction"))
      : null,
  });

  useEffect(() => {
    const filterKeys = [
      { key: "powerBankSerialNumber", value: filters.powerBankSerialNumber },
      { key: "transactionId", value: filters.transactionId },
      { key: "isInTransaction", value: filters.isInTransaction },
    ];

    filterKeys.forEach(({ key, value }) => {
      updateSearchParam(searchParams, key, value);
    });

    setSearchParams(searchParams);
  }, [filters, searchParams, setSearchParams]);

  return (
    <Container>
      <Header>
        <Title>Power banks</Title>
        <PowerBanksInfo />
      </Header>
      <FiltersContainer>
        <Input
          label="Power Bank Serial Number"
          placeholder="12345678"
          value={filters.powerBankSerialNumber}
          onChange={(value: string) =>
            setFilters({ ...filters, powerBankSerialNumber: value })
          }
        />
        <Input
          label="Transaction ID"
          sublabel='Ignored if "Is on a module" is selected in "Transaction status"'
          placeholder="12345678"
          value={filters.transactionId}
          onChange={(value: string) =>
            setFilters({ ...filters, transactionId: value })
          }
        />
        <Select
          label="Transaction status"
          value={transactionStatusOptions.find(
            ({ value }) => value === filters.isInTransaction
          )}
          options={transactionStatusOptions}
          onChange={(option) =>
            setFilters({ ...filters, isInTransaction: option?.value ?? null })
          }
          isClearable
        />
      </FiltersContainer>
      <PowerBanksList filters={filters} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 20px;
  overflow: hidden;
  padding: 15px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const FiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;
