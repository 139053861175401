import styled from "styled-components";
import { Title } from "../../design-system/atoms/Title";
import { Input } from "../../design-system/atoms/Input";
import { useEffect, useState } from "react";
import { ModuleAuthorizationsList } from "./components/ModuleAuthorizationsList";
import { useSearchParams } from "react-router-dom";
import { Button } from "../../design-system/atoms/Button";
import { Plus } from "../../design-system/atoms/icons/Plus";
import { NewModuleAuthorizationModal } from "./components/NewModuleAuthorizationModal";
import { useCreateModuleAuthorizationMutation } from "../../data/generated";
import { toast } from "react-toastify";
import { useModuleAuthorizationHandlers } from "../../hooks/moduleAuthorizationHandlers";

export const ModuleAuthorizations = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [moduleSerialNumberFilter, setModuleSerialNumberFilter] = useState(
    () => searchParams.get("moduleSerialNumberFilter") || ""
  );
  const [
    isNewModuleAuthorizationModalOpen,
    setIsNewModuleAuthorizationModalOpen,
  ] = useState(false);

  const [createModuleAuthorizationMutation] =
    useCreateModuleAuthorizationMutation();

  const { handleNewModuleAuthorization } = useModuleAuthorizationHandlers();

  useEffect(() => {
    if (moduleSerialNumberFilter) {
      searchParams.set("moduleSerialNumberFilter", moduleSerialNumberFilter);
    } else {
      searchParams.delete("moduleSerialNumberFilter");
    }
    setSearchParams(searchParams);
  }, [moduleSerialNumberFilter, searchParams, setSearchParams]);

  async function createModuleAuthorizations({
    moduleSerialNumbers,
  }: {
    moduleSerialNumbers: string[];
  }) {
    const { data } = await createModuleAuthorizationMutation({
      variables: { moduleSerialNumbers },
    });

    if (!data) {
      toast.error("An unexpected error occurred");
      return;
    }

    const {
      createModuleAuthorization: { moduleAuthorizations, error },
    } = data;

    if (error) {
      toast.error(error);
      return;
    }

    if (!moduleAuthorizations) {
      toast.error("An unexpected error occurred");
      return;
    }

    handleNewModuleAuthorization({
      newModuleAuthorizations: moduleAuthorizations,
      filters: { moduleSerialNumber: moduleSerialNumberFilter },
    });
    toast.success("Module authorizations created successfully");
    setIsNewModuleAuthorizationModalOpen(false);
  }

  return (
    <Container>
      <Header>
        <Title>Module authorizations</Title>
        <Button onClick={() => setIsNewModuleAuthorizationModalOpen(true)}>
          <Plus
            width={16}
            height={16}
            fillColor="currentColor"
            strokeWidth={2}
          />
        </Button>
      </Header>
      <FiltersContainer>
        <Input
          label="Module Serial Number"
          placeholder="12345678"
          value={moduleSerialNumberFilter}
          onChange={(value: string) => setModuleSerialNumberFilter(value)}
        />
      </FiltersContainer>
      <ModuleAuthorizationsList
        moduleSerialNumberFilter={moduleSerialNumberFilter}
      />
      {isNewModuleAuthorizationModalOpen ? (
        <NewModuleAuthorizationModal
          createModuleAuthorizations={createModuleAuthorizations}
          onClose={() => setIsNewModuleAuthorizationModalOpen(false)}
        />
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 20px;
  overflow: hidden;
  padding: 15px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const FiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;
