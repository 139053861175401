import styled from "styled-components";
import { ApiRequestsList } from "./components/ApiRequestsList";
import { Title } from "../../design-system/atoms/Title";
import { useEffect, useState } from "react";
import { DatetimePicker } from "../../design-system/atoms/DatetimePicker";
import { useSearchParams } from "react-router-dom";
import { updateSearchParam } from "../../utils/searchParams";
import { ApiRequestOrigin, ApiRequestType } from "../../data/generated";
import { Select } from "../../design-system/atoms/Select";
import { Input } from "../../design-system/atoms/Input";

const originOptions = [
  { value: ApiRequestOrigin.Dashboard, label: "Dashboard" },
  { value: ApiRequestOrigin.External, label: "External" },
];

const typeOptions = [
  { value: ApiRequestType.GetModulesDetails, label: "GetModulesDetails" },
  { value: ApiRequestType.GetPowerBanksDetails, label: "GetPowerBanksDetails" },
  { value: ApiRequestType.LendPowerBank, label: "LendPowerBank" },
  { value: ApiRequestType.RestartModule, label: "RestartModule" },
  {
    value: ApiRequestType.UnlockModulePowerBanks,
    label: "UnlockModulePowerBanks",
  },
  {
    value: ApiRequestType.UpdateModuleIntervals,
    label: "UpdateModuleIntervals",
  },
  {
    value: ApiRequestType.UpdateModuleMiddlewareUrl,
    label: "UpdateModuleMiddlewareUrl",
  },
  {
    value: ApiRequestType.UpdateModuleSerialNumber,
    label: "UpdateModuleSerialNumber",
  },
];

export const ApiRequests = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [filters, setFilters] = useState<{
    serialNumber: string;
    origin: ApiRequestOrigin | null;
    type: ApiRequestType | null;
    afterTimestamp: number | null;
    beforeTimestamp: number | null;
  }>({
    serialNumber: searchParams.get("serialNumber") || "",
    origin: (searchParams.get("origin") as ApiRequestOrigin) || null,
    type: (searchParams.get("type") as ApiRequestType) || null,
    afterTimestamp: searchParams.get("afterTimestamp")
      ? Number(searchParams.get("afterTimestamp"))
      : null,
    beforeTimestamp: searchParams.get("beforeTimestamp")
      ? Number(searchParams.get("beforeTimestamp"))
      : null,
  });

  useEffect(() => {
    const filterKeys = [
      { key: "serialNumber", value: filters.serialNumber },
      { key: "origin", value: filters.origin },
      { key: "type", value: filters.type },
      { key: "afterTimestamp", value: filters.afterTimestamp },
      { key: "beforeTimestamp", value: filters.beforeTimestamp },
    ];

    filterKeys.forEach(({ key, value }) => {
      updateSearchParam(searchParams, key, value);
    });

    setSearchParams(searchParams);
  }, [filters, searchParams, setSearchParams]);

  return (
    <Container>
      <Title>Api requests</Title>
      <FiltersContainer>
        <Input
          label="Serial Number (module or power bank)"
          placeholder="12345678"
          value={filters.serialNumber}
          onChange={(value: string) =>
            setFilters({ ...filters, serialNumber: value })
          }
        />
        <Select
          label="Type"
          value={typeOptions.find(({ value }) => value === filters.type)}
          options={typeOptions}
          onChange={(option) =>
            setFilters({ ...filters, type: option?.value ?? null })
          }
          isClearable
          style={{ minWidth: 300 }}
        />
        <Select
          label="Origin"
          value={originOptions.find(({ value }) => value === filters.origin)}
          options={originOptions}
          onChange={(option) =>
            setFilters({ ...filters, origin: option?.value ?? null })
          }
          isClearable
          style={{ minWidth: 300 }}
        />
        <DatetimePicker
          label="After date"
          value={filters.afterTimestamp}
          onChange={(value) =>
            setFilters({
              ...filters,
              afterTimestamp: value,
            })
          }
        />
        <DatetimePicker
          label="Before date"
          value={filters.beforeTimestamp}
          onChange={(value) =>
            setFilters({
              ...filters,
              beforeTimestamp: value,
            })
          }
        />
      </FiltersContainer>
      <ApiRequestsList filters={filters} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 20px;
  overflow: hidden;
  padding: 15px;
`;

const FiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;
